import React, { useState } from "react";
import styled from "styled-components";
import CardGeneric from "../../components/common/CardGeneric";
import FormAudit from "../../components/templates/audit/FormAudit";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';


const Audit = () => {

    const [state, setState] = useState(null)

    const handleRefresh = () => {
        setState(true);
        setState(null);
    }

    if (state) {
        return <div/>
    }

    return <AuditWrapper>
        <CardGeneric icon={<VerifiedUserIcon/>} title="Solicitar auditoria médica">
            <FormAudit handleRefresh={handleRefresh}/>
        </CardGeneric>
    </AuditWrapper>
}

export default Audit;

const AuditWrapper = styled.div`
    width: 100%;
`