import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import CardGeneric from "../../components/common/CardGeneric";
import { Text } from "../../components/common/Text";
import FormControl from '@material-ui/core/FormControl';
import { Autocomplete } from '@material-ui/lab';
import PageviewIcon from '@material-ui/icons/Pageview';
import { useDispatch, useSelector } from "react-redux";
import { utilsActions } from "../../redux/actions";
import PageLoading from "../../components/common/PageLoading";
import { ButtonGreen } from "../../components/common/ButtonsGenerics";
import { searchProviderBySpeciality } from "../../redux/api/utils";
import TableGeneric from "../../components/common/TableGeneric";
import { TextField } from "@material-ui/core";
import DownloadExternal from "../../components/ui/organisms/external/DownloadExternal";
import SearchGeneric from "../../components/common/SearchGeneric";

const External = () => {

    // VARIABLES
    const columns = [
        { id: 'name', label: 'Prestador' },
        { id: 'street', label: 'Dirección' },
        { id: 'phone', label: 'Telefono' },
    ]

    // REDUX
    const specialties = useSelector(state => state.utils.specialties)
    const dispatch = useDispatch()

    // STATE
    const [currentSpecialties, setCurrentSpecialties] = useState(null)
    const [providerState, setProvider] = useState(null)
    const [currentProvider, setCurrentProvider] = useState(null)
    const [providerFiltered, setProviderFiltered] = useState(null);
    const [loading, setLoading] = useState(false);

    // EFFECTS
    useEffect(() => {
        if (specialties === null) {
            dispatch(utilsActions.specialtiesRequest())
        }
    }, [])

    // FUNCTIONS
    const handleSpecialties = (e, value) => {
        if (value !== null) {
            setCurrentSpecialties(value.id)
        }
    }

    const handleSubmit = async () => {
        setCurrentProvider(false)
        setLoading(true)
        const provider = await searchProviderBySpeciality(currentSpecialties, providerState || '');
        if (provider.data) {
            tableRender(provider.data)
        }
        setLoading(false)
    }

    const handleFilter = filtered => {
        setProviderFiltered(filtered)
    }

    const tableRender = (currentProvider) => {
        if (currentProvider) {
            let result = []
            currentProvider.map((row, i) => {
                return result.push(
                    {
                        ...row,
                        street: `${row.direccion || ''} ${row.direccion_numero || ''} CP(${row.zip || '-'})`,
                        phone: !!row.phone && !!row.mobile ? (`${row.phone} | ${row.mobile}`) : (`${!!row.phone ? row.phone : ""}${!!row.mobile ? row.mobile : ""}`)
                    }
                )

            })
            setCurrentProvider(result)
            return result
        }
        else {
            setCurrentProvider([])
            return []
        }
    }

    const handleProvider = (e) => setProvider(e.target.value)

    // RETURNS
    if (specialties === null) {
        return <PageLoading h="100px" />
    }


    return <ExternalWrapper>
        <CardGeneric title={"Busqueda prestador externo"} icon={<LocalHospitalIcon />}>
            <Text fontSize=".9rem" fontWeight="700" color="#555555">CRITERIO DE BÚSQUEDA</Text>
            <Container>
                <FormControlFake>
                    <Autocomplete
                        id="combo-box-demo"
                        options={specialties}
                        getOptionLabel={(option) => option.name}
                        onChange={handleSpecialties}
                        renderInput={(params) => <TextField {...params} value={currentSpecialties} label="Seleccione una especialidad" variant="standard" />}
                    />
                </FormControlFake>
                <TextField
                    label="Prestador"
                    type="text"
                    value={providerState}
                    onChange={handleProvider}
                />
            </Container>
            {currentSpecialties !== null && (
                <Footer>
                    <ButtonGreen onClick={handleSubmit}>BUSCAR</ButtonGreen>
                </Footer>
            )}
        </CardGeneric>
        {!!currentProvider &&
            (
                <CardGeneric title={"Resultados de búsqueda"} icon={<PageviewIcon />}>
                    <Header>
                        <SearchGeneric title="Buscar proveedor" handleValues={handleFilter} filterNames={['phone', 'street', 'name']} values={currentProvider} />
                        <DownloadExternal print={providerFiltered || currentProvider} />
                    </Header>
                    <TableGeneric columns={columns} rows={providerFiltered || currentProvider} pageSize={10} />
                </CardGeneric>
            )
        }
        {!!loading && (
            <CardGeneric title={"Resultados de búsqueda"} icon={<PageviewIcon />}>
                <PageLoading h="100px"/>
            </CardGeneric>
        )}
    </ExternalWrapper>
}

export default External

const ExternalWrapper = styled.div`
    width: 100%;
    height: 100px;
    background-color: red;
`

const Container = styled.div`
    /* padding: 3rem; */
    width: 100%;
    display: grid;
    grid-template-columns: 2.5fr 1fr;
    align-items: center;
    gap: 1rem;
    height: auto;
`

const FormControlFake = styled(FormControl)`
    width: calc(100%);
`

const Footer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1rem 0;
`

const Header = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`