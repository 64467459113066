import React from "react";
import styled from "styled-components";
import logo from "../../../assets/images/afiliado.png";
import logoOnline from "../../../assets/images/logo-online.png";
import DataUser from "../../ui/atoms/aside/DataUser";
import ListItemOpenMobile from "../../ui/atoms/aside/ListItemOpenMobile";


const AsideMobile = (props) => {
    const { aside } = props

    const rol = "Afiliado"

    return <AsideWrapper aside={!aside}>
        <Header>
            <LogoDamsu>
                <Logo w={"80px"} h={aside ? "auto" : "27px"} src={logoOnline} />
            </LogoDamsu>
            <HeaderContent>
                <LogoWrapper aside={!aside}>
                    <Logo w={"25px"} h={"25px"} src={logo} />
                </LogoWrapper>
                <Rol>{rol}</Rol>
            </HeaderContent>
        </Header>
        <Body>
            {/* User */}
            <DataUser aside={true} />
            {/* List Menu  */}
            <ListItemOpenMobile/>       
        </Body>
    </AsideWrapper>
}

export default AsideMobile;

const AsideWrapper = styled.aside`
    width: 240px;
    height: 100vh;
    background-color: #528bab;
    box-shadow: -1px 1px 13px 0px rgba(53,53,53,0.75);
    -webkit-box-shadow: -1px 1px 13px 0px rgba(53,53,53,0.75);
    -moz-box-shadow: -1px 1px 13px 0px rgba(53,53,53,0.75);
    display: flex;
    justify-content: center;
    flex-direction: column;
    transition: .5s ease-in-out all;
    ${p => p.aside ? "width: 80px;" : null};
    

    @media (max-width: 800px) {
        display: ${p => !p.aside ? "block" : "none"};
        
    }

`

const Header = styled.div`
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    padding-top: 0;
    flex-direction: column;
`

const HeaderContent = styled.div`
    background-color: white;
    border-radius: 3px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-evenly;
`

const Logo = styled.img`
    width: ${props => props.w ? props.w : "50px"};
    height: ${props => props.h ? props.h : "50px"};
    margin: .5rem;
    opacity: .5;
`

const LogoWrapper = styled.div`
    width: 40%;
    ${p => p.aside ? " width: 100%" : null};
    display: flex;
    justify-content: flex-end;
    ${p => p.aside ? "justify-content: center" : null};
    align-items: center;
    height: 100%;
`

const LogoDamsu = styled.div`
    width: 100%;
    height: 100px;
    padding: .3rem 0;
    display: flex;
    justify-content: center;
`

const Rol = styled.div`
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    color: #528bab;
    font-weight: 600;
    font-size: .9rem;
    text-transform: uppercase;
    padding-left: 1rem;
`

const Body = styled.div`
    height: calc( 100% - 50px );
    max-height: calc( 100% - 50px );
    overflow-y: auto;
    padding: 1rem;
    padding-top: 0;
`