import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TableGeneric from "../../components/common/TableGeneric";
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CardGeneric from "../../components/common/CardGeneric";
// import { useSelector } from "react-redux";
import PageLoading from "../../components/common/PageLoading";
import ErrorPage from "../../components/common/ErrorPage";
import { ButtonBlue, ButtonRed } from "../../components/common/ButtonsGenerics";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DialogGeneric from "../../components/common/DialogGeneric";
import InfoOrder from "../../components/ui/organisms/orders/InfoOrder";
import { useSnackBar } from "../../hooks/useSnackBar";
import { SnackBarGeneric } from "../../components/common/SnackBarGeneric";
import { useSelector } from "react-redux";
import SearchGeneric from "../../components/common/SearchGeneric";
import { getRefunds } from "../../redux/api/refunds";
import SearchHistoryRefunds from "../../components/templates/refunds/SearchHistoryRefunds";
import DeleteRefund from "../../components/ui/organisms/refunds/DeleteRefund";
import InfoRefund from "../../components/ui/organisms/refunds/InfoRefund";
import { Button } from "@material-ui/core";
import TakeRefunds from "../../components/templates/refunds/TakeRefunds";

const Refunds = () => {

    const columns = [
        { id: 'fecha_solicitud', label: 'Fecha de solicitud' },
        { id: 'id', label: 'N° de Reintegro' },
        { id: 'type', label: 'Tipo de Reintegro' },
        { id: 'estado', label: 'Estado' },
        { id: 'acciones', label: 'Acciones' },
    ]

    const [refunds, setRefunds] = useState(null)
    const [ordersPage, setOrdersPage] = useState(null)
    const [historyRefunds, setHistoryRefunds] = useState(null)
    const [ordersFiltered, setOrdersFiltered] = useState(null);
    const [historyFiltered, setHistoryFiltered] = useState(null);
    const [closeDialog, setCloseDialog] = useState(false)

    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();

    const { user } = useSelector(state => state.auth)

    useEffect(() => {
        if (!refunds) getRefundsRequest()
    }, [])

    const getRefundsRequest = async () => {
        setRefunds(null)
        const request = await getRefunds(user.afiliado_id)
        if (request.error) {
            setRefunds(false)
            return
        }
        tableOrders(request)
    }

    const handleHistoryRefunds = (orders) => {
        tableHistoryOrders(orders)
    }

    const handleStatus = (state) => {
        // setCloseModal(null)
        if (state) {
            openSnackBar(
                "success",
                "¡El reintegro se anuló con éxito!"
            );
            setTimeout(() => {
                getRefundsRequest()
            }, 1500);
        }
        else{
            openSnackBar(
                "error",
                "¡Error al anular reintegro!"
            );        
        }
    }

    const handleNextPage = () => {
        setOrdersPage(1)
    }

    const handleForceClose = () => {
        setCloseDialog(!closeDialog)
    }

    const handleHistory = filtered => {
        setHistoryFiltered(filtered)
    }

    const handleFilterRefunds = filtered => {
        setOrdersFiltered(filtered)
    }

    const tableOrders = (refunds) => {
        let result = []
        if (refunds) {
            result = refunds.map(refund => {
                return {
                    ...refund,
                    type: refund.tipo_reintegro.nombre || '-',
                    acciones: <Actions>
                        <DialogGeneric handleForceClose={handleForceClose} close={closeDialog} button={<ButtonBlue><FormatListBulletedIcon /></ButtonBlue>} component={<InfoRefund handleClose={handleForceClose} id={refund.id}/>}/>
                        <DialogGeneric handleForceClose={handleForceClose} close={closeDialog} button={<ButtonRed><DeleteForeverIcon /></ButtonRed>} component={<DeleteRefund user={user.afiliado_id} id={refund.id} handleForceClose={handleForceClose} handleStatus={handleStatus}/>}/>
                    </Actions>,
                }
            })
        }
        setRefunds(result.reverse())
    }

    const tableHistoryOrders = (historyRefunds) => {
        let result = []
        if (historyRefunds) {
            result = historyRefunds.map(refund => {
                return {
                    ...refund,
                    type: refund.tipo_reintegro.nombre || '-',
                    acciones: <Actions>
                        <DialogGeneric button={<ButtonBlue><FormatListBulletedIcon /></ButtonBlue>} component={<InfoRefund id={refund.id}/>}/>
                    </Actions>,
                }
            })
        }
        setHistoryRefunds(result)
    }

    const handleRefresh = () => {
        setOrdersPage(null)
    }

    if (refunds === null) {
        return <PageLoading h="50vh" w="100%" />
    }

    if (refunds === false) {
        return <CardGeneric title={"reintegros en curso"} icon={<ReceiptIcon />}>
            <ErrorPage button={<ButtonBlue onClick={getRefundsRequest}>Reintentar</ButtonBlue>} message="¡No se pudo obtener los reintegros!" />
        </CardGeneric>
    }

    if (ordersPage === 1) {
        return <TakeRefunds handleRefresh={handleRefresh}/>
    }


    return <OrdersWrapper>
        <CardGeneric title={"Reintegros en curso"} icon={<ReceiptIcon />} handleClick={handleNextPage} button={Button} buttonName={"Solicitar"}>
            <SearchGeneric title="Buscar reintegros" margin="0 0 15px 0" handleValues={handleFilterRefunds} filterNames={['id', 'fecha_solicitud', 'estado']} values={refunds} />
            <TableGeneric columns={columns} rows={ordersFiltered || refunds} pageSize={5} />
        </CardGeneric>
        <CardGeneric title={"Historial de reintegros"} icon={<SearchRoundedIcon />} >
            <SearchHistoryRefunds handleHistoryRefunds={handleHistoryRefunds}/>
        </CardGeneric>
        {historyRefunds && (
            <CardGeneric title={""} icon={<SearchRoundedIcon />} >
                <SearchGeneric margin="0 0 15px 0" handleValues={handleHistory} filterNames={['id', 'fecha_solicitud', 'estado']} values={historyRefunds} />
                <TableGeneric columns={columns} rows={historyFiltered || historyRefunds} pageSize={5} />
            </CardGeneric>
        )}
        <SnackBarGeneric
            show={snackBar.show}
            handleClose={closeSnackBar}
            message={snackBar.message}
            variant={snackBar.variant}
        />
    </OrdersWrapper>
}

export default Refunds;

const OrdersWrapper = styled.div`
    width: 100%;
`

const Actions = styled.div`
    display: flex;
    justify-content: flex-start;
`