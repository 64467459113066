import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TableGeneric from "../../components/common/TableGeneric";
import { Button } from "@material-ui/core";
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CardGeneric from "../../components/common/CardGeneric";
import { donwloadPDFOrder, getOrders } from "../../redux/api/orders";
// import { useSelector } from "react-redux";
import PageLoading from "../../components/common/PageLoading";
import ErrorPage from "../../components/common/ErrorPage";
import { ButtonBlue, ButtonGreen, ButtonRed } from "../../components/common/ButtonsGenerics";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import TakeOrders from "../../components/templates/orders/TakeOrders";
import SearchHistoryOrders from "../../components/templates/orders/SearchHistoryOrders";
import DialogGeneric from "../../components/common/DialogGeneric";
import InfoOrder from "../../components/ui/organisms/orders/InfoOrder";
import DeleteOrder from "../../components/ui/organisms/orders/DeleteOrder";
import { useSnackBar } from "../../hooks/useSnackBar";
import { SnackBarGeneric } from "../../components/common/SnackBarGeneric";
import { useSelector } from "react-redux";
import SearchGeneric from "../../components/common/SearchGeneric";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { formatCurrency } from "../../helpers/currency.format.helper";

const Orders = () => {

    const columns = [
        { id: 'nameAff', label: 'Afiliado' },
        { id: 'fecha_solicitud', label: 'Fecha' },
        { id: 'id', label: 'N° de orden' },
        { id: 'prestador', label: 'Prestador' },
        { id: 'importe_total', label: 'Importe' },
        { id: 'codigo', label: 'Código de validación' },
        { id: 'acciones', label: 'Acciones' },
    ]

    const [orders, setOrders] = useState(null)
    const [ordersPage, setOrdersPage] = useState(null)
    const [historyOrders, setHistoryOrders] = useState(null)
    const [ordersFiltered, setOrdersFiltered] = useState(null);
    const [historyFiltered, setHistoryFiltered] = useState(null);
    const [closeDialog, setCloseDialog] = useState(false)
    const [loadingPdf, setLoadingPdf] = useState(false)

    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();

    const { user } = useSelector(state => state.auth)

    useEffect(() => {
        if (!orders) getOrdersRequest()
    }, [])

    const getOrdersRequest = async () => {
        setOrders(null)
        const request = await getOrders()
        if (request.error) {
            setOrders(false)
            return
        }
        setOrders(request.rows)
    }

    const handleNextPage = () => {
        setOrdersPage(1)
    }

    const handleBackPage = () => {
        setOrdersPage(null)
    }

    const handleHistoryOrders = (orders) => {
        setHistoryOrders(orders)
    }

    const handleStatus = (state) => {
        // setCloseModal(null)
        if (state) {
            openSnackBar(
                "success",
                "¡La orden se anuló con éxito!"
            );
            setTimeout(() => {
                getOrdersRequest()
            }, 1500);
        }
        else {
            openSnackBar(
                "error",
                "¡Error al anular la orden!"
            );
        }
    }

    const handleRefresh = () => {
        setOrders(null)
        setOrdersPage(null)
        getOrdersRequest()
    }

    const handleForceClose = () => {
        setCloseDialog(!closeDialog)
    }

    const handleHistory = filtered => {
        setHistoryFiltered(filtered)
    }

    const handleFilterOrders = filtered => {
        setOrdersFiltered(filtered)
    }

    const handleDownload = async (id) => {
        setLoadingPdf(id)
        const req = await donwloadPDFOrder(id)
        setLoadingPdf(false)
        if (req.error) {
            openSnackBar(
                "error",
                "¡Error al generar el PDF!"
            );
        }
        else {
            generatePdf(`data:application/pdf;base64,${req.pdf}`, id)
        }
    }

    const generatePdf = (file, name) => {
        fetch(file)
            .then(res => res.blob())
            .then(blob => {
                const file = new File([blob], "ordern-" + name, { type: "application/pdf" })
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(file);
                link.download = "order-" + name;
                link.click();
            })
    };

    function dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    const tableOrders = (orders) => {
        // console.log('orders', orders)
        let result = []
        if (orders) {
            result = orders.map(order => {
                if (order.estado_orden_id.name === "Disponible") {
                    return {
                        ...order,
                        prestador: order.prestador_id?.name,
                        nameAff: order?.afiliado_id?.name,
                        importe_total: `$${formatCurrency(order?.importe_total?.toFixed(2))}`,
                        acciones: <Actions>
                            <ButtonGreen disabled={loadingPdf === order.id} onClick={() => handleDownload(order.id)}>{loadingPdf === order.id ? 'Cargando' : <PictureAsPdfIcon />}</ButtonGreen>
                            <DialogGeneric handleForceClose={handleForceClose} close={closeDialog} button={<ButtonBlue><FormatListBulletedIcon /></ButtonBlue>} component={<InfoOrder handleClose={handleForceClose} id={order.id} />} />
                            <DialogGeneric handleForceClose={handleForceClose} close={closeDialog} button={<ButtonRed><DeleteForeverIcon /></ButtonRed>} component={<DeleteOrder id={order.id} handleForceClose={handleForceClose} handleStatus={handleStatus} />} />
                        </Actions>,
                    }
                }
                else {
                    return {
                        ...order,
                        prestador: order.prestador_id?.name,
                        nameAff: order?.afiliado_id?.name,
                        importe_total: `$${formatCurrency(order?.importe_total?.toFixed(2))}`,
                        acciones: <Actions>
                            <DialogGeneric handleForceClose={handleForceClose} close={closeDialog} button={<ButtonBlue><FormatListBulletedIcon /></ButtonBlue>} component={<InfoOrder handleClose={handleForceClose} id={order.id} />} />
                        </Actions>,
                    }
                }
            })
        }
        return (result.reverse())
    }

    const tableHistoryOrders = (historyOrders) => {
        let result = []
        if (historyOrders) {
            result = historyOrders.map(order => {
                return {
                    ...order,
                    nameAff: order?.afiliado_id?.name,
                    prestador: order.prestador_id?.name,
                    acciones: <Actions>
                        <DialogGeneric handleForceClose={handleForceClose} button={<ButtonBlue><FormatListBulletedIcon /></ButtonBlue>} component={<InfoOrder id={order.id} />} />
                        {/* <DialogGeneric handleForceClose={handleForceClose} close={closeDialog} button={<ButtonRed><DeleteForeverIcon /></ButtonRed>} component={<DeleteOrder id={order.id} handleForceClose={handleForceClose} handleStatus={handleStatus} />} /> */}
                    </Actions>,
                }
            })
        }
        return (result)
    }

    if (orders === null) {
        return <PageLoading h="50vh" w="100%" />
    }

    if (orders === false) {
        return <CardGeneric title={"Órdenes disponibles"} icon={<ReceiptIcon />}>
            <ErrorPage button={<ButtonBlue onClick={getOrdersRequest}>Reintentar</ButtonBlue>} message="¡No se pudo obtener las ordenes!" />
        </CardGeneric>
    }

    if (ordersPage) {
        return <TakeOrders handleRefresh={handleRefresh} handleBackPage={handleBackPage} />
    }


    return <OrdersWrapper>
        <CardGeneric title={"Órdenes disponibles"} icon={<ReceiptIcon />} handleClick={handleNextPage} button={Button} buttonName={"Solicitar"}>
            <SearchGeneric title="Buscar ordenes" margin="0 0 15px 0" handleValues={handleFilterOrders} filterNames={['id', 'codigo', 'prestador']} values={tableOrders(orders)} />
            <TableGeneric columns={columns} rows={ordersFiltered || tableOrders(orders)} pageSize={5} />
        </CardGeneric>
        <CardGeneric title={"Historial de órdenes"} icon={<SearchRoundedIcon />} >
            <SearchHistoryOrders handleHistoryOrders={handleHistoryOrders} />
        </CardGeneric>
        {historyOrders && (
            <CardGeneric title={""} icon={<SearchRoundedIcon />} >
                <SearchGeneric margin="0 0 15px 0" handleValues={handleHistory} filterNames={['id', 'codigo', 'prestador']} values={tableHistoryOrders(historyOrders)} />
                <TableGeneric columns={columns} rows={historyFiltered || tableHistoryOrders(historyOrders)} pageSize={5} />
            </CardGeneric>
        )}
        <SnackBarGeneric
            show={snackBar.show}
            handleClose={closeSnackBar}
            message={snackBar.message}
            variant={snackBar.variant}
        />
    </OrdersWrapper>
}

export default Orders;

const OrdersWrapper = styled.div`
    width: 100%;
`

const Actions = styled.div`
    display: flex;
    justify-content: flex-start;
`