import { ButtonBase } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import { deleteOrderById } from '../../../../redux/api/orders';
import { ButtonGreen } from '../../../common/ButtonsGenerics';
import PageLoading from '../../../common/PageLoading';
import { Text } from '../../../common/Text';

const DeleteOrder = props => {
    const { id, handleStatus, handleForceClose } = props

    const [loading, setLoading] = useState(false)

    const handleDelete = async () => {
        setLoading(true)
        const request = await deleteOrderById(id)
        setLoading(false)
        if (request.error) { 
            handleStatus(false)
        }
        else{
            handleStatus(true)
            
        }
        setTimeout(() => {
            handleForceClose()
        }, 1500);
    }

    return <DeleteWrapper>
        <Header>
            <LogoWrapper>
                <Text fontWeight="600" color="white" fontSize="3rem">!</Text>
            </LogoWrapper>
        </Header>
        <Body>
            <Text fontWeight="600" color="#555555" fontSize="2rem">¿Seguro desea anular la orden?</Text>
        </Body>
        {!loading ? (
            <Footer>
                <ButtonGreen onClick={handleDelete}>Confirmar</ButtonGreen>
                &nbsp;
                <ButtonBase onClick={handleForceClose}>Cancelar</ButtonBase>
            </Footer>
        ):(
            <PageLoading w="100%" h="100px"/>
        )}
    </DeleteWrapper>
}

export default DeleteOrder

const DeleteWrapper = styled.div`
    width: auto;
    min-width: 400px;
`

const Header = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Body = styled.div`
    width: 100%;
    margin: 3rem 0;
    text-align: center;
`

const Footer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`

const LogoWrapper = styled.div`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #e34d4b;
    display: flex;
    justify-content: center;
    align-items: center;
`