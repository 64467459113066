import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { getOrdersByDate } from "../../../redux/api/orders";
import { ButtonGreen } from "../../common/ButtonsGenerics";
import PageLoading from "../../common/PageLoading";

const SearchHistoryOrders = props => {

    const { handleHistoryOrders } = props

    const { user, family } = useSelector(state => state.auth)

    const affiliates = [user, ...family]

    const [affiliate, setAffiliate] = useState(null);
    const [loading, setLoading] = useState(false)
    const [dates, setDates] = useState({
        initial_date: null,
        finish_date: null,
    })

    const handleChangeAffiliate = (event) => {
        setAffiliate(event.target.value);
    };

    const handleDate = (e) => {
        setDates({...dates, [e.target.name]: e.target.value } )
    }

    const handleSubmit =  async () => {
        setLoading(true)
        const request = await getOrdersByDate(affiliate,dates)
        if (request.error) {
            setLoading(false)
            return
        }
        setLoading(false)
        handleHistoryOrders(request.rows)
    }

    return <SearchHistoryOrdersWrapper>
        <Grid>
        <FormControlFake>
            <InputLabel id="1">Seleccione un afiliado</InputLabel>
            <SelectFake
                labelId="1"
                value={affiliate}
                onChange={handleChangeAffiliate}
            >
                {affiliates.map(member => <MenuItem value={member.afiliado_id}>{`${member.nombre} ${member.apellido}`}</MenuItem>)}
            </SelectFake>
        </FormControlFake>
        <TextFieldFake
            id="date"
            label="Fecha inicial"
            type="date"
            name="initial_date"
            value={dates.initial_date}
            onChange={handleDate}
            InputLabelProps={{
                shrink: true,
            }}
        />
        <TextFieldFake
            id="date"
            label="Fecha final"
            type="date"
            name="finish_date"
            onChange={handleDate}
            value={dates.finish_date}
            InputLabelProps={{
                shrink: true,
            }}
        />
        </Grid>
        {!!affiliate && !!dates.initial_date && !!dates.finish_date && (
            <SearchContainer>
                {!loading ? (
                    <ButtonGreen onClick={handleSubmit}>Buscar</ButtonGreen>
                ):
                (
                    <PageLoading w="100%" h="auto"/>
                )}
            </SearchContainer>
        )}
    </SearchHistoryOrdersWrapper>
}

export default SearchHistoryOrders;

const SearchHistoryOrdersWrapper = styled.div`
    width: 100%;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    padding: 1rem 0;
    @media (max-width: 800px) {
        grid-template-columns: 1fr;
    }
`

const FormControlFake = styled(FormControl)`
    width: 100%;
`

const SelectFake = styled(Select)`
    width: 100%;
`

const TextFieldFake = styled(TextField)`
    width: 100%;
`

const SearchContainer = styled.div`
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`