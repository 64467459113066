import { ButtonBase } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getRefundById } from '../../../../redux/api/refunds';
import ErrorPage from '../../../common/ErrorPage';
import PageLoading from '../../../common/PageLoading';
import { Text } from '../../../common/Text';

const InfoRefund = props => {
    const { id, handleClose } = props

    const [information, setInformation] = useState(null)
    const [error, setError] = useState(false)

    useEffect(() => {
        if (information === null) {
            getInfo(id)
        }
    }, [information, id])

    const getInfo = async (id) => {
        const request = await getRefundById(id)
        if (request.error) {
            setError(true)
            return
        }
        console.log(request);
        setInformation(request)
    }

    if (error === true) {
        return <ErrorPage message="No se pudo obtener la información de esta orden" />
    }

    if (information === null) {
        return <PageLoading w="400px" h="200px" />
    }

    return <InfoOrderWrapper>
        <Header>
            <Text color="#333333" fontSize="1.7rem" fontWeight="600">Detalle de reintegro</Text>
            <ButtonBase onClick={handleClose}><Text color="#888888" fontSize="1rem" fontWeight="700">X</Text></ButtonBase>
        </Header>
        <Body>
            <Grid col="repeat(3,1fr)">
                <div>
                    <Text color="#333333" fontSize="1rem" fontWeight="700">Fecha de Solicitud</Text>
                    <Text color="#333333" fontSize="1rem" fontWeight="600">{information.fecha_solicitud}</Text>
                </div>
                <div>
                    <Text color="#333333" fontSize="1rem" fontWeight="700">N° Reintegro</Text>
                    <Text color="#333333" fontSize="1rem" fontWeight="600">{information.id}</Text>
                </div>
                <div>
                    <Text color="#333333" fontSize="1rem" fontWeight="700">Afiliado</Text>
                    <Text color="#333333" fontSize="1rem" fontWeight="600">{information.afiliado?.nombre}</Text>
                </div>
            </Grid>
            <HR/>
            <Text color="#333333" fontSize="1.2rem" fontWeight="700">FACTURA</Text>
            <Grid col="repeat(3,1fr)">
                    <Th><Text color="#333333" fontSize="1rem" fontWeight="700">CUIT</Text></Th>
                    <Th><Text color="#333333" fontSize="1rem" fontWeight="700">Tipo</Text></Th>
                    <Th><Text color="#333333" fontSize="1rem" fontWeight="700">Número</Text></Th>
                    <Text color="#333333" fontSize="1rem" fontWeight="600">{information?.factura?.factura_cuit}</Text>
                    <Text color="#333333" fontSize="1rem" fontWeight="600">{information?.factura?.factura_tipo}</Text>
                    <Text color="#333333" fontSize="1rem" fontWeight="600">{information?.factura?.factura_nro}</Text>
            </Grid>
            <HR/>
            <List>
                <Text color="#333333" fontSize="1.2rem" fontWeight="700">PRESTACIÓN /MEDICAMENTO</Text>
                <Grid col="3fr repeat(4,1fr)">
                    <Th><Text color="#333333" fontSize="1rem" fontWeight="700">Nombre</Text></Th>
                    <Th><Text color="#333333" fontSize="1rem" fontWeight="700">Cantidad</Text></Th>
                    <Th><Text color="#333333" fontSize="1rem" fontWeight="700">Precio unitario</Text></Th>
                    <Th><Text color="#333333" fontSize="1rem" fontWeight="700">%Reconocido </Text></Th>
                    <Th><Text color="#333333" fontSize="1rem" fontWeight="700">Monto a reintegrar</Text></Th>

                    {information?.prestaciones.map(item => (<>
                        <Text color="#333333" fontSize="1rem" fontWeight="600">{item.nombre}</Text>
                        <Text color="#333333" fontSize="1rem" fontWeight="600">{item.cantidad}</Text>
                        <Text color="#333333" fontSize="1rem" fontWeight="600">${item.precio_unitario}</Text>
                        <Text color="#333333" fontSize="1rem" fontWeight="600">${item.porcentaje_damsu}</Text>
                        <Text color="#333333" fontSize="1rem" fontWeight="600">%{item.monto_a_reintegrar}</Text>
                    </>))}
                </Grid>
            </List>
        </Body>
    </InfoOrderWrapper>
}

export default InfoRefund

const InfoOrderWrapper = styled.div`
    width: 100%;
    min-width: 400px;
`

const Header = styled.div`
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #999999;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Body = styled.div`
    width: 100%;
    margin: 1rem 0;
`

const Grid = styled.div`
    margin: 1rem 0;
    display: grid;
    grid-template-columns: ${p => p.col};
`

const List = styled.div`
    width: 100%;
    @media (max-width: 768px) {
        width: 600px;
        overflow-x: auto;
    }
    @media (max-width: 468px) {
        width: 380px;
        overflow-x: auto;
    }
`

const Th = styled.div`
    width: 120px;
`

const HR = styled.div`
    height: 1px;
    width: 100%;
    background-color: #cccccc;
    margin: 15px 0;
`