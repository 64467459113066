import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import CallIcon from '@material-ui/icons/Call';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import PageLoading from "../../../common/PageLoading";
import { getDataUser } from "../../../../redux/api/affiliate";
import ErrorPage from "../../../common/ErrorPage";
import { Text } from "../../../common/Text";
import { ButtonBlue } from "../../../common/ButtonsGenerics";


const ViewData = (props) => {

    const { id, handleClose } = props

    const [affiliate, setAffiliate] = useState(null)

    useEffect(() => {
        if (affiliate === null) {
            getDataUserService()
        }
    }, [affiliate])

    const getDataUserService = async () => {
        const result = await getDataUser(id)
        if (result.data) {
            setAffiliate(result.data)
        }
        else {
            setAffiliate(false)
        }
    }

    if (affiliate === null) {
        return <PageLoading h="20vh" w="300px" visible={true} />
    }

    if (affiliate === false) {
        return <ErrorPage />
    }

    return <ViewDataWrapper>
        <Header>
            <Text fontSize="1.2rem" fontWeight="700" color="rgb(82 139 171)">{affiliate.nombre} {affiliate.apellido}</Text>
        </Header>
        <Body>
            <LogoContainer>
                <ProfileImg src={affiliate.img || "https://p.kindpng.com/picc/s/78-785827_user-profile-avatar-login-account-male-user-icon.png"} />
            </LogoContainer>
            <DescriptionUser>               

                <Title>Parentesco</Title>
                <ValueData>{affiliate.titular ? "Titular" : "Afiliado"}</ValueData>

                <Title>Tipo doc</Title>
                <ValueData>{affiliate.tipo_documento.name}</ValueData>

                <Title>Documento</Title>
                <ValueData>{affiliate.numero_documento}</ValueData>

                <Title>Datos de contacto</Title>
                <ValueDataIcon>
                    <LocationOnIcon />
                    {`${affiliate.direccion}`}
                </ValueDataIcon>
                <ValueDataIcon><AlternateEmailIcon />{affiliate.email}</ValueDataIcon>

                <ValueDataIcon><SmartphoneIcon />{affiliate.mobile}</ValueDataIcon>
                <ValueDataIcon><CallIcon />{affiliate.phone}</ValueDataIcon>
            </DescriptionUser>
        </Body>
        <Footer>
            <ButtonBlue onClick={handleClose}>Cerrar</ButtonBlue>
        </Footer>
    </ViewDataWrapper>
}

export default ViewData

const ViewDataWrapper = styled.div`
    width: 100%;
    max-width: 100%;
`
const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
`

const DescriptionUser = styled.div`
    width: 100%;
    display: grid;
    padding: 0 3rem;
`


const ProfileImg = styled.img`
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    width: 130px;
    height: 130px;
`

const Title = styled.div`
    text-transform: uppercase;
    font-size: .7rem;
    color: #6f6f6f;
    font-weight: 600;
`

const ValueData = styled.div`
    color: #393939;
    font-size: .8rem;
    font-weight: 600;
    margin-bottom: .5rem;
`

const ValueDataIcon = styled.div`
    color: #393939;
    font-size: .8rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    svg{
        transform: scale(.6);
    }
`

const Body = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: 1rem 0;
`

const Header = styled.div`
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #999999;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

const Footer = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`