import * as types from "../types/auth";
import { call, put, takeEvery } from "redux-saga/effects";
import { loginUser } from "../api/auth";
import { authActions } from "../actions";


function* loginRequest(action) {
	try {
		const res = yield call(loginUser, action.payload);
		console.log(res);
		yield put(
			authActions.loginUserSuccess({
				user: res.data.user,
				token: res.data.access_token,
			})
		);
	} catch (error) {
		yield put(authActions.loginUserFail(error));
	}
}


function* authWatcher() {
	yield takeEvery(types.LOGIN_USER_REQUEST, loginRequest);
}
export { authWatcher };