import { TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useSnackBar } from '../../../hooks/useSnackBar'
import { changePasswordToken } from '../../../redux/api/affiliate'
import { ButtonBlue } from '../../common/ButtonsGenerics'
import { SnackBarGeneric } from '../../common/SnackBarGeneric'

const FormResetToken = (props) => {

    const { token } = props

    const [pass, setPass] = useState('')
    const [confirmPass, setConfirmPass] = useState('')
    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();

    const history = useHistory()

    const handlePass = (e) => {
        setPass(e.target.value)
    }

    const handlePassConf = (e) => {
        setConfirmPass(e.target.value)
    }

    const handleSumbit = async () => {
        if (pass.length < 8) {
            openSnackBar(
                "error",
                "¡Contraseña demasiado corta! mínimo 8 carateres"
            );
            return
        }
        const body = {
            password: pass
        }
        const req = await changePasswordToken(token, body)
        if (req.error) {
            openSnackBar(
                "error",
                "¡Ocurrió un error!"
            );
        }
        else{
            openSnackBar(
                "success",
                "¡Se cambio la contraseña correctamente!"
            );
        }
        setTimeout(() => {
            history.push('./auth')
        }, 1000);
    }

    return (
        <Wrapper>
            <TextField value={pass} onChange={handlePass} label="Ingresar contraseña"/>
            <TextField value={confirmPass} onChange={handlePassConf} label="Confirmar contraseña"/>
            <ButtonBlue disabled={!pass || !confirmPass || confirmPass !== pass ? true : false} onClick={handleSumbit}>Confirmar cambio</ButtonBlue>
            <SnackBarGeneric
                show={snackBar.show}
                handleClose={closeSnackBar}
                message={snackBar.message}
                variant={snackBar.variant}
            />
        </Wrapper>
    )
}

export default FormResetToken

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 12px 0;
`