import React from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { layoutActions } from "../../../../redux/actions";

const ItemMenu = (props) => {
    const { icon, title, to, isMobile = false } = props

    const dispatch = useDispatch()
    const history = useHistory()

    const active = history.location.pathname === to

    if (active) {
        dispatch(layoutActions.setPageNow(title));
    }

    if (isMobile) {
        return (<Href onClick={() => dispatch(layoutActions.setMenuMode(false))} to={to}>
            <Container active={active}>{icon}<Title active={active}>{title}</Title></Container>
        </Href>
        )
    }

    return (<Href to={to}>
        <Container active={active}>{icon}<Title active={active}>{title}</Title></Container>
    </Href>
    )
}

export default ItemMenu;

const Container = styled.div`
    width: calc( 100% - 1rem );
    height: 35px;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    cursor: pointer;
    transition: all .3s ease-in-out;
    border-radius: 3px;
    margin: .5rem 0;
    svg, span {
            color: white;
    }
    span {
        color: white;
    }

    :hover {
        background-color: #4e7991;
    }

    ${props => props.active ? (
        `
            :hover{
                background-color: white;
                svg, span {
                    color: #2f424d;
                }
                span {
                    color: white;
                }
            }
            background-color: white;
            svg, span {
                color: #2f424d;
            }
            span {
                color: white;
            }
            
            `
    )
        :
        null
    }
    
`

const Href = styled(Link)`
    text-decoration: none;
`

const Title = styled.div`
    width: auto;
    padding-left: 1rem;
    text-decoration: none;
    color: white;

    ${props => props.active ? (
        `   
            color: #2f424d;
            `
    )
        :
        null
    }
`