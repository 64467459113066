import { MenuItem, Select, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useSnackBar } from '../../../hooks/useSnackBar';
import { getPharmacyByDate } from '../../../redux/api/phamarcy';
import { ButtonGreen } from '../../common/ButtonsGenerics';
import PageLoading from '../../common/PageLoading';
import { SnackBarGeneric } from '../../common/SnackBarGeneric';

const FormPharmacyHistory = props => {

    const { handleResult } = props

    const { user, family } = useSelector(state => state.auth)

    const affiliates = [user, ...family]

    const [affiliate, setAffiliate] = useState(null);
    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();
    const [dates, setDates] = useState({
        initial: null,
        finish: null
    })
    const [loading, setLoading] = useState(false)

    const handleChangeAffiliate = (event) => {
        setAffiliate(event.target.value);
    };

    const handleDate = e => {
        setDates({...dates, [e.target.name]: e.target.value})
    } 

    const handleSubmit = async () => {
        // Request
        setLoading(true)
        const request = await getPharmacyByDate({initial: dates.initial, finish: dates.finish, affiliate})
        if (request.error) {
            setLoading(false)
            openSnackBar(
                "error",
                "¡Error al buscar en el historial!"
            );
            return
        }
        else{
            setLoading(false)
            handleResult(request)
        }
    }

    return <FormPharmacyHistoryWrapper>
        <Grid>
            <Select
                fullWidth
                value={affiliate}
                onChange={handleChangeAffiliate}
            >
                {affiliates.map(member => <MenuItem value={member.afiliado_id}>{`${member.nombre} ${member.apellido}`}</MenuItem>)}
            </Select>
            <TextField onChange={handleDate} value={dates.initial} name="initial" type="date" placeholder="Fecha inicial"/>
            <TextField onChange={handleDate} value={dates.finish} name="finish" type="date" placeholder="Fecha final"/>
        </Grid>
        <Footer>
            {!loading ? (
                <ButtonGreen disabled={dates.initial === null || dates.finish === null || !affiliate} onClick={handleSubmit}>Buscar</ButtonGreen>
            ) : (
                <PageLoading w="100%" h="auto"/>
            )}
            
        </Footer>
        <SnackBarGeneric
            show={snackBar.show}
            handleClose={closeSnackBar}
            message={snackBar.message}
            variant={snackBar.variant}
        />
    </FormPharmacyHistoryWrapper>
}

export default FormPharmacyHistory;

const FormPharmacyHistoryWrapper = styled.div`
    width: 100%;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    @media (max-width: 800px) {
        grid-template-columns: 1fr;  
    }
`

const Footer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
`