import axios from "axios";

export async function getTypeAuditService() {
	try {
		const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/tipo_auditoria/`);
		return response.data
	} catch (error) {
		return { error }
	}
}

export async function createAudit(body) {
	try {
		const response = await axios.post(`${process.env.REACT_APP_DANSUAPI}/private/auditoria_medica`, body);
		return response.data
	} catch (error) {
		return { error }
	}
}