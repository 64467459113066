import axios from "axios";

export async function getOrders() {
	try {
        const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/orden/?estado=disponible`);
        return response.data
    } catch (error) {
        return {error}
    }
}

export async function getOrdersByDate(id,{initial_date, finish_date}) {
	try {
        const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/orden/search/?afiliado_id=${id}&estado=all&fecha_fin=${finish_date}&fecha_inicio=${initial_date}`);
        return response.data
    } catch (error) {
        return {error}
    }
}

export async function getOrderById(id) {
	try {
        const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/orden/${id}`);
        return response.data
    } catch (error) {
        return {error}
    }
}

export async function deleteOrderById(id) {
	try {
        const response = await axios.post(`${process.env.REACT_APP_DANSUAPI}/private/orden/${id}`);
        return response.data
    } catch (error) {
        return {error}
    }
}


export async function createOrder(body) {
	try {
        const response = await axios.post(`${process.env.REACT_APP_DANSUAPI}/private/orden/`, body);
        return response.data
    } catch (error) {
        return { error }
    }
}

export async function donwloadPDFOrder(id) {
	try {
        const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/orden/pdf/${id}`);
        return response.data
    } catch (error) {
        return {error}
    }
}