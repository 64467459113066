import { ButtonBase } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { formatCurrency } from '../../../../helpers/currency.format.helper';
import { getOrderById } from '../../../../redux/api/orders';
import ErrorPage from '../../../common/ErrorPage';
import PageLoading from '../../../common/PageLoading';
import { Text } from '../../../common/Text';

const InfoOrder = props => {
    const { id, handleClose } = props

    const [information, setInformation] = useState(null)
    const [error, setError] = useState(false)

    useEffect(() => {
        if (information === null) {
            getInfo(id)
        }
    }, [information, id])

    const getInfo = async (id) => {
        const request = await getOrderById(id)
        if (request.error) {
            setError(true)
            return
        }
        setInformation(request)
    }

    if (error === true) {
        return <ErrorPage message="No se pudo obtener la información de esta orden" />
    }

    if (information === null) {
        return <PageLoading w="400px" h="200px" />
    }

    console.log(information?.prestacion_ids);

    return <InfoOrderWrapper>
        <Header>
            <Text color="#333333" fontSize="1.7rem" fontWeight="600">Detalle de orden</Text>
            <ButtonBase onClick={handleClose}><Text color="#888888" fontSize="1rem" fontWeight="700">X</Text></ButtonBase>
        </Header>
        <Body id="body">
            <Grid col="repeat(4,1fr)">
                <div>
                    <Text color="#333333" fontSize="1rem" fontWeight="700">Fecha</Text>
                    <Text color="#333333" fontSize="1rem" fontWeight="600">{information.fecha_emision}</Text>
                </div>
                <div>
                    <Text color="#333333" fontSize="1rem" fontWeight="700">N° Orden</Text>
                    <Text color="#333333" fontSize="1rem" fontWeight="600">{information.id}</Text>
                </div>
                <div>
                    <Text color="#333333" fontSize="1rem" fontWeight="700">Prestador</Text>
                    <Text color="#333333" fontSize="1rem" fontWeight="600">{information.prestador_id?.name}</Text>
                </div>
                <div>
                    <Text color="#333333" fontSize="1rem" fontWeight="700">Código de validación</Text>
                    <Text color="#333333" fontSize="1rem" fontWeight="600">{information.codigo !== "" ? information.codigo : "No se encuentra el código"}</Text>
                </div>
            </Grid>
            <Grid col="repeat(1,1fr)">
                <div>
                    <Text color="#333333" fontSize="1rem" fontWeight="700">Nombre</Text>
                    <Text color="#333333" fontSize="1rem" fontWeight="600">{information.profesional_id?.name}</Text>
                </div>
            </Grid>
            <List id="overflow">
                <Grid col="3fr .5fr 2fr repeat(3, auto)">
                    <Th><TextFake color="#333333" fontSize="1rem" fontWeight="700">Prestación</TextFake></Th>
                    <Th><TextFake color="#333333" fontSize="1rem" fontWeight="700">Cantidad</TextFake></Th>
                    <Th><TextFake color="#333333" fontSize="1rem" fontWeight="700">Precio unitario</TextFake></Th>
                    <Th><TextFake color="#333333" fontSize="1rem" fontWeight="700">Total</TextFake></Th>
                    <Th><TextFake color="#333333" fontSize="1rem" fontWeight="700">%Descuento</TextFake></Th>
                    <Th><TextFake color="#333333" fontSize="1rem" fontWeight="700">A abonar</TextFake></Th>

                    {information?.prestacion_ids.map(item => (<>
                        <TextFake color="#333333" fontSize="1rem" fontWeight="600">{item.display_name}</TextFake>
                        <TextFake color="#333333" fontSize="1rem" fontWeight="600">{item.cantidad}</TextFake>
                        <TextFake color="#333333" fontSize="1rem" fontWeight="600">${formatCurrency(item.importe_unitario?.toFixed(2))}</TextFake>
                        <TextFake color="#333333" fontSize="1rem" fontWeight="600">${formatCurrency(item.importe_total?.toFixed(2))}</TextFake>
                        <TextFake color="#333333" fontSize="1rem" fontWeight="600">{item.descuento_damsu?.toFixed(2)}%</TextFake>
                        <TextFake color="#333333" fontSize="1rem" fontWeight="600">${formatCurrency(item.abonar?.toFixed(2))}</TextFake>
                    </>))}
                </Grid>
            </List>
            <Grid col="repeat(2,1fr)">
                <Block>Importe</Block>
                <Right><Text fontSize="1.3rem" color="#222222">${formatCurrency(information?.importe_total?.toFixed(2))}</Text></Right>
                <Block>Descuento</Block>
                <Right><Text fontSize="1.3rem" color="#e34d4b">- ${formatCurrency(information?.importe_damsu?.toFixed(2))}</Text></Right>
                <Block>A Cargo del afiliado</Block>
                <Right><Text fontSize="1.6rem" color="#222222">${formatCurrency(information?.importe_a_abonar?.toFixed(2))}</Text></Right>
            </Grid>
        </Body>
    </InfoOrderWrapper>
}

export default InfoOrder

const InfoOrderWrapper = styled.div`
    width: 100%;
    min-width: auto;
    height: auto;
    /* background-color: white; */
    @media (max-width: 468px) {
        height: 90vh;
        overflow-y: scroll;
    }
    #overflow{
        height: 200px;
        overflow-y: auto;
        @media (max-width: 468px) {
            max-width: 350px;
            overflow-x: auto;
        }
    }
    #body{
        @media (max-width: 468px) {
            max-height: 70vh;
            overflow-y: scroll;
        }
    }
`

const Header = styled.div`
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #999999;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Body = styled.div`
    width: calc(100% - 30px);
    margin: 1rem 0;
`

const Grid = styled.div`
    margin: 1rem 0;
    display: grid;
    grid-template-columns: ${p => p.col};
`

const Block = styled.div`
    width: 100%;
    /* max-width: 150px; */
    background-color: #7dbd6a;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    margin: .1rem 0;
`

const Right = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    margin: .1rem 0;
`

const List = styled.div`
    width: 100%;
    @media (max-width: 768px) {
        width: 600px;
        overflow-x: auto;
    }
    @media (max-width: 468px) {
        width: 380px;
        overflow-x: auto;
    }
`

const Th = styled.div`
    /* width: 120px; */
    @media (max-width: 468px) {
        min-width: 120px;
    }
`

const TextFake = styled(Text)`
    border: 1px solid #aaaaaa;
    padding: 6px 2px;
`