import { Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useForm } from '../../../../hooks/useForm';
import { useSnackBar } from '../../../../hooks/useSnackBar';
import { createOrder } from '../../../../redux/api/orders';
import { getProfesionalByProvider, getProvider, getProfesional, getPlansService } from '../../../../redux/api/utils';
import { ButtonGreen } from '../../../common/ButtonsGenerics';
import InputFile from '../../../common/InputFile';
import PageLoading from '../../../common/PageLoading';
import InfoUser from '../../../common/user/InfoUser';
import { SnackBarGeneric } from "../../../common/SnackBarGeneric";
import { Autocomplete } from '@material-ui/lab';

const initialForm = {
    affiliate: null,
    plan: null,
    provider: null,
    profesional: null,
    medical: null,
    message: null,
    comentario: null,
    typeC: 0,
}


const OrderForm = props => {

    const { handleRefresh } = props

    const [affiliate, setAffiliate] = useState(null);
    const [providers, setProviders] = useState(null);
    const [profesional, setProfesional] = useState(null)
    const [providerField, setProviderField] = useState(null)
    const [profesionalField, setProfesionalField] = useState(null)
    const [plans, setPlans] = useState(null)
    const [file, setFile] = useState(null)
    const [fileName, setFileName] = useState(null)

    const [loading, setloading] = useState(false)

    // Form
    const [currentProvider, setCurrentProvider] = useState(null)
    const [form, handleFormInput, status] = useForm(initialForm)
    const { user, family } = useSelector(state => state.auth)

    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();

    const affiliates = [user, ...family]

    useEffect(() => {
        getPlans(form.affiliate)
    }, [form.affiliate])


    useEffect(() => {
        getProviderComp()
        // getMedical()
    }, [])

    useEffect(() => {
        // console.log(currentProvider);
        if (currentProvider !== null) getProfesionalComp(currentProvider)
    }, [currentProvider])

    const [checked, setChecked] = React.useState(false);

    const handleChange = (event) => {
        if (!event.target.checked) {
            setProfesionalField(null)
            setProviderField(null)
        }
        setChecked(event.target.checked);
    };

    const handleChangeAffiliate = (event) => {
        setAffiliate(affiliates.filter(aff => aff.afiliado_id === event.target.value)[0]);
    };

    const handleProvider = (_, value) => {
        // console.log(value);
        setCurrentProvider(value?.id || null)
    }

    const getProviderComp = async () => {
        const requestProvider = await getProvider()
        if (!requestProvider.error) {
            setProviders(requestProvider)
        }
    }

    const getPlans = async (id) => {
        const request = await getPlansService(id || user.afiliado_id)
        if (!request.error) {
            setPlans(request)
        }
    }

    const getProfesionalComp = async id => {
        // console.log("Busco");
        const request = await getProfesionalByProvider(id)
        if (!request.error) {
            setProfesional(request)
        }
    }

    // const getMedical = async () => {
    //     const request = await getProfesional()
    //     if (!request.error) {
    //         setMedical(request)
    //     }
    // }

    const handleB64File = (files) => {
        setFile(files)
    }

    const handleSubmit = async () => {
        setloading(true)
        let data = {
            afiliado_id: form.affiliate,
            plan_id: form.plan,
            prestador_id: form.provider || null,
            prestador: providerField || null,
            profesional: profesionalField || null,
            profesional_id: form.profesional || null,
            medico_solicitante: null,
            prestacion: form.message,
            comentario: `Médico solicitante: ${form?.medical || '-'} \n
                        Prestación: ${form?.message || '-'} \n
                        Comentario: ${form.comentario || '-'}`,
            images: file,
            // image_name: fileName
        }

        const request = await createOrder(data)
        setloading(false)
        if (request.error) {
            openSnackBar(
                "error",
                "Error al crear la orden"
            );
        }
        else {
            openSnackBar(
                "success",
                "Se creó correctamente"
            );
            setTimeout(() => {
                handleRefresh()
            }, 1500);
        }
    }

    const handleDisabled = () => {
        if (form.typeC === 1 && !file) {
            return true
        }
        if (checked) {
            return !providerField || !form.plan || !form.affiliate ? true : false
        }
        else {
            return !form.provider || !form.plan || !form.affiliate ? true : false
        }

    }

    return <OrderFormWrapper>
        <Left>
            <FormControlFake>
                <InputLabel id="1">Seleccione un afiliado *</InputLabel>
                <SelectFake
                    labelId="1"
                    value={form.affiliate}
                    onChange={e => {
                        handleChangeAffiliate(e)
                        handleFormInput(e)
                    }}
                    name="affiliate"
                >
                    {affiliates.map(member => <MenuItem value={member.afiliado_id}>{`${member.nombre} ${member.apellido}`}</MenuItem>)}
                </SelectFake>
            </FormControlFake>
            <Grid>
                {!!plans && <FormControlFake>
                    <InputLabel id="1">Plan *</InputLabel>
                    <SelectFake
                        disabled={!plans}
                        labelId="1"
                        name="plan"
                        value={form.plan}
                        onChange={handleFormInput}
                    >
                        {plans.map(plan => <MenuItem value={plan.id}>{`${plan.name}`}</MenuItem>)}
                    </SelectFake>
                </FormControlFake>}
                {!checked ? !!providers ? (
                    <FormControlFake>
                        <Autocomplete
                            id="combo-box-demo"
                            options={providers}
                            getOptionLabel={(option) => option.name}
                            disabled={!providers}
                            onChange={(e, value) => {
                                handleProvider(e, value)
                                handleFormInput({
                                    target: {
                                        value: value?.id || null,
                                        name: 'provider'
                                    }
                                })
                            }}
                            renderInput={(params) => <TextField {...params} value={form.provider} label="Prestador *" variant="standard" />}
                        />
                    </FormControlFake>
                ) : (<TextField fullWidth disabled label="Prestador" />) : (
                    <TextField value={providerField} onChange={e => setProviderField(e.target.value)} label="Escribir prestador" />
                )}
            </Grid>
            {!checked ? !!profesional && (
                <FormControlFake>
                    <InputLabel id="1">Profesional</InputLabel>
                    <SelectFake
                        // onChange={}
                        disabled={profesional.length === 0 ? true : false}
                        labelId="1"
                        name="profesional"
                        value={form.profesional}
                        onChange={handleFormInput}
                    >
                        {profesional.map(profe => <MenuItem value={profe.id}>{`${profe.nombre} ${profe.apellido}`}</MenuItem>)}
                    </SelectFake>
                </FormControlFake>
            ) : <TextField value={profesionalField} onChange={e => setProfesionalField(e.target.value)} fullWidth label="Escribir profesional/especialidad" />}
            {/* Check */}
            <FormGroup>
                <FormControlLabel control={<Checkbox color="primary" checked={checked}
                    onChange={handleChange} />} label="No encontré el prestador y/o el profesional" />
            </FormGroup>
            <TextField
                fullWidth
                name="medical"
                value={form.medical}
                onChange={handleFormInput}
                label="Médico solicitante"
            />
            <Margin />
            <FormControlFake>
                <InputLabel id="1">Tipo prestacion</InputLabel>
                <SelectFake
                    labelId="1"
                    name="typeC"
                    value={form.typeC}
                    onChange={handleFormInput}
                >
                    {[{ id: 0, name: 'Consulta médica' }, { id: 1, name: 'Práctica' }].map(plan => <MenuItem value={plan.id}>{`${plan.name}`}</MenuItem>)}
                </SelectFake>
            </FormControlFake>
            {form.typeC === 1 && (
                <TextFieldFake
                    id="date"
                    label="Prestacion *"
                    type="text"
                    multiline
                    rows={2}
                    name="message"
                    value={form.message}
                    onChange={handleFormInput}
                />
            )}
        </Left>
        <Right>
            {affiliate !== null && (
                <InfoUser affiliate={affiliate} />
            )}
            <TextFieldFake
                id="date"
                label="Comentario"
                type="text"
                multiline
                rows={4}
                name="comentario"
                value={form.comentario}
                onChange={handleFormInput}
            />
            <InputFile handleB64File={handleB64File} />
        </Right>
        <Footer>
            {!loading ?
                (<ButtonGreen onClick={handleSubmit} disabled={handleDisabled()}>Solicitar orden</ButtonGreen>) :
                (<PageLoading h="auto" w="100%" />)}
        </Footer>
        <SnackBarGeneric
            show={snackBar.show}
            handleClose={closeSnackBar}
            message={snackBar.message}
            variant={snackBar.variant}
        />
    </OrderFormWrapper>
}

export default OrderForm;

const OrderFormWrapper = styled.div`
    width: auto;
    display: grid;
    display: grid;
    grid-template-areas: "left right"
                         "footer footer";
    gap: .5rem;
    @media (max-width: 800px) {
        grid-template-columns: 1fr;
        grid-template-areas: "left" 
                            "right"
                            "footer";
    }
`
const Grid = styled.div`
    width: auto;
    display: grid;
    gap: 1rem;
    margin: 1rem 0;
`

const Left = styled.div`
    grid-area: left;
    width: 100%;
`

const Right = styled.div`
    grid-area: right;
    width: 100%;
`

const FormControlFake = styled(FormControl)`
    width: 100%;
`

const SelectFake = styled(Select)`
    width: 100%;
`

const TextFieldFake = styled(TextField)`
    width: 100%;
    margin: 1rem 0;
`

const Footer = styled.div`
    grid-area: footer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Margin = styled.div`
    margin-top: 15px;
`