import { MenuItem, Select, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ButtonGreen } from '../../common/ButtonsGenerics';
import InfoUser from '../../common/user/InfoUser';
import { Text } from '../../common/Text';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import { useForm } from '../../../hooks/useForm';
import { useSnackBar } from '../../../hooks/useSnackBar';
import { SnackBarGeneric } from '../../common/SnackBarGeneric';
import { getContact, sendMail } from '../../../redux/api/contact';
import PageLoading from '../../common/PageLoading';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';


export const ContactForm = () => {
    const { user } = useSelector(state => state.auth)

    const whatsapp = `https://api.whatsapp.com/send/?phone=5492614864064&text=Hola%2C+tengo+una+consulta+desde+Damsu+Online`

    const initialForm = {
        subject: null,
        message: null
    }

    const [form, handleFormInput, status] = useForm(initialForm)
    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();
    const [select, setSelect] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!select) getSelectContact()
    }, [select])

    const getSelectContact = async () => {
        const request = await getContact()
        if (request.error) {
            return
        }
        else {
            setSelect(request)
        }
    }

    const handleSubmit = async (e) => {
        const data = {
            mensaje: form.message,
            asunto: "Consulta desde Damsu Online",
            tipo_consulta_id: form.subject
        }
        setLoading(true)
        const request = await sendMail(data)
        if (request.error) {
            openSnackBar(
                "error",
                "¡Ocurrió un error inesperado!"
            );
            setLoading(false)
            return
        }
        setLoading(false)
        openSnackBar(
            "success",
            "¡Consulta enviada correctamente!"
        );
    }

    return (
        <ContactFormWrapper>
            <Form>
                <Text color="#333333" fontWeight="600">AFILIADO</Text>
                <InfoUser affiliate={user} />
                <br />
                <Text color="#333333" fontWeight="600">DATOS DE CONTACTO</Text>
                <Gap>
                    <Value><EmailIcon /><Text color="#333333" fontWeight="500">{user.email}</Text></Value>
                    <Value><PhoneIcon /><Text color="#333333" fontWeight="500">{user.phone}</Text></Value>
                    <Value><PhoneAndroidIcon /><Text color="#333333" fontWeight="500">{user.mobile}</Text></Value>
                </Gap>
            </Form>
            <Form>
                <Text color="#333333" fontWeight="600">PARA CONSULTA Y/O SUGERENCIAS CONTÁCTENOS AL 0800-122-0807</Text>
                {/* <WhatsappButton>
                    <ButtonGreen startIcon={<WhatsAppIcon/>} onClick={() => window.open(whatsapp)}>Whatsapp</ButtonGreen>
                </WhatsappButton> */}
                
                {/* <Text color="#333333" fontWeight="600">CONSULTAS VÍA E-MAIL</Text>
                {!!select && (
                    <SelectFake
                        fullWidth
                        placeholder="Seleccionar un sector"
                        value={form.subject}
                        onChange={handleFormInput}
                        name="subject"
                    >
                        {select.map(menu => <MenuItem value={menu.id}>{menu.tipo_consulta}</MenuItem>)}
                    </SelectFake>
                )}
                <TextFieldFake
                    placeholder="Ingresa tu consulta"
                    fullWidth
                    multiline
                    rows={4}
                    value={form.message}
                    onChange={handleFormInput}
                    name="message"
                ></TextFieldFake>
                <Footer>
                    {!loading ? (
                        <ButtonGreen onClick={handleSubmit} disabled={!status}>Enviar consulta</ButtonGreen>
                    )
                        :
                        (<PageLoading w="100%" h="auto" />)}
                </Footer> */}
            </Form>
            <SnackBarGeneric
                show={snackBar.show}
                handleClose={closeSnackBar}
                message={snackBar.message}
                variant={snackBar.variant}
            />
        </ContactFormWrapper>
    )
}

const ContactFormWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 800px) {
        grid-template-columns: 1fr;  
    }
`

const Form = styled.div`
    padding: 2rem 1rem;
`

const Gap = styled.div`
    padding: 2rem 1rem;
    display: grid;
    gap: .5rem;
`

const SelectFake = styled(Select)`
    margin: 1rem 0;
`

const TextFieldFake = styled(TextField)`
    margin: 1rem 0;
`

const Value = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    align-items: center;
    svg{
        color: #333333;
        font-size: 1rem;
    }
`

const Footer = styled.div`
    margin: 1rem 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
`

const WhatsappButton = styled.div`
    margin: 1rem 0;
    width: 100%;
    display: flex;
    justify-content: center;
`