import React from "react";
import PersonIcon from '@material-ui/icons/Person';
import EventIcon from '@material-ui/icons/Event';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import ReceiptIcon from '@material-ui/icons/Receipt';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import HistoryIcon from '@material-ui/icons/History';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import ItemMenu from "./ItemMenu";
import AccordionMenu from "./AccordionMenu";
import { RenderTransition } from "../../../common/RenderTransition";
import { useSelector } from "react-redux";

const ListItemOpenMobile = () => {

    const { user } = useSelector(state => state.auth)

    const pharmacy = [
        {
            name: "Solicitud medicamentos",
            to: "request-pharmacy"
        },
        {
            name: "Historial de consumo",
            to: "consumption-history"
        },
        {
            name: "Stock de medicamentos",
            to: "stock-medicines"
        },
    ]

    const providers = [
        {
            name: "Prestadores internos",
            to: "internal-provider"
        },
        {
            name: "Prestadores externos",
            to: "external-provider"
        },
    ]

    const currentAccount = [
        {
            name: "Deuda actual",
            to: "current-debt"
        },
        {
            name: "Deuda histórica",
            to: "historical-debt"
        },
    ]

    return (<RenderTransition>
            <ItemMenu isMobile={true} to={"/affiliate"} title={"Afiliado"} icon={<PersonIcon />} />
            <ItemMenu isMobile={true} to={"/shifts"} title={"Turnos"} icon={<EventIcon />} />
            <ItemMenu isMobile={true} to={"/orders"} title={"Órdenes"} icon={<ReceiptIcon />} />
            <AccordionMenu isMobile={true} submenus={pharmacy} title={"Farmacia"} icon={<LocalHospitalIcon />} />
            <ItemMenu isMobile={true} to={"/audit"} title={"Auditoria médica"} icon={<VerifiedUserIcon />} />
            <AccordionMenu isMobile={true} submenus={providers} title={"Prestadores"} icon={<PermContactCalendarIcon />} />
            <ItemMenu isMobile={true} to={"/refunds"} title={"Reintegros"} icon={<HistoryIcon />} />
            {user.titular && <AccordionMenu isMobile={true} submenus={currentAccount} title={"C. Corriente"} icon={<AccountBalanceWalletIcon />} />}
            <ItemMenu isMobile={true} to={"/contact"} title={"Contacto"} icon={<ContactSupportIcon />} />
    </RenderTransition>)
}

export default ListItemOpenMobile;

