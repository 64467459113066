import * as types from "../types/auth";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";

const initialState = {
	user: null,
	family: null,
	authToken: null,
	error: null,
};

export const auth = persistReducer(
	{ storage, key: "auth-damsu", whitelist: ["user", "authToken", "family"] },
	(state = initialState, action) => {
		switch (action.type) {
			// LOGIN USER
			case types.LOGIN_USER_REQUEST:
				return {
					...state,
					loading: true,
					error: null,
				};
			case types.LOGIN_USER_SUCCESS:
				return {
					...state,
					loading: false,
					authToken: action.payload.token,
					family: action.payload.user.grupo_familiar,
					user: action.payload.user,
				};
			case types.LOGIN_USER_FAIL:
				return {
					...state,
					loading: false,
					error: action.payload,
				};
			case types.LOGOUT_USER:
				localStorage.clear();
				return initialState
			case types.UPDATE_USER:
				return {
					...state,
					loading: false,
					user: {
						...state.user,
						...action.payload,
					},
				};
			default:
				return state;
		}
	}
);
