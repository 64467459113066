import { Button, IconButton, Input, InputAdornment, MenuItem, Select, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { validateDocument } from "../../../helpers/InputValidate.helper";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useSnackBar } from "../../../hooks/useSnackBar";
import { SnackBarGeneric } from "../../common/SnackBarGeneric";
import PageLoading from "../../common/PageLoading";
import { getTypeDocuments } from "../../../redux/api/utils";
import { registerForm, registerPassword } from "../../../redux/api/auth";
import { Span, Text } from "../../common/Text";

const RegisterLogin = () => {

    // Hooks
    const history = useHistory()
    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();

    // State
    const [user, setUser] = useState("")
    const [userError, setUserError] = useState(true)
    const [pass, setPass] = useState("")
    const [confirmPass, setConfirmPass] = useState("")
    const [status, setStatus] = useState(false)
    const [typeDocument, setTypeDocument] = useState(null)
    const [typeInputPass, setTypeInputPass] = useState("password")
    const [loading, setLoading] = useState(false)
    const [date, setDate] = useState('')
    const [email, setEmail] = useState("")
    const [type, setType] = useState(11)

    useEffect(() => {
        !typeDocument && getType()
    }, [])


    const getType = async () => {
        const request = await getTypeDocuments()
        if (request.error) {
            openSnackBar(
                "error",
                'Ocurrió un error'
            );
        }
        else {
            setTypeDocument(request)
        }
    }

    // Functions
    const handleUser = (e) => {
        setUserError(validateDocument(e.target.value))
        setUser(e.target.value)
    }

    const handlePass = (e) => {
        setPass(e.target.value)
    }

    const handleConfirmPass = (e) => {
        setConfirmPass(e.target.value)
    }

    const handleDate = (e) => {
        setDate(e.target.value)
    }

    const handleEmail = (e) => {
        setEmail(e.target.value)
    }

    const handleType = e => {
        setType(e.target.value)
    }

    const handleSubmit = async () => {
        setLoading(true)
        const body = {
            tipo_documento: type,
            numero_documento: parseInt(user, 10),
            fecha_nacimiento: date,
            email: email
        }
        const request = await registerForm(body)
        setLoading(false)
        if (request.error) {
            openSnackBar(
                "error",
                'Ocurrió un error'
            );
        }
        else {
            setStatus(request)
        }
    }

    const handleSetPassword = async () => {
        if (pass === confirmPass) {
            setLoading(true)
            const request = await registerPassword({password: pass}, status.access_token)
            setLoading(false)
            if (request.error) {
                openSnackBar(
                    "error",
                    'Ocurrió un error en el registro'
                );
            }
            else{
                openSnackBar(
                    "success",
                    '¡Registro exitoso!'
                );
                setTimeout(() => {
                    history.push('/login')
                }, 2000);
            }
        }
        else{
            openSnackBar(
                "error",
                'Las contraseñas no coinciden'
            );
        }
    }

    const handleTypeViewPass = () => {
        setTypeInputPass(typeInputPass === "password" ? "text" : "password")
    }

    if (status) {
        return <RegisterLoginWrapper>
            <Text color="#222">Hola, <Span fontWeight="bold">{status.nombre}</Span> ingresá una nueva contraseña</Text>
            <Form>
                <InputFake
                    type={typeInputPass}
                    value={pass}
                    onChange={handlePass}
                    placeholder="Contraseña"
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleTypeViewPass}
                                onMouseDown={handleTypeViewPass}
                            >
                                {typeInputPass === "password" ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <InputFake
                    type={typeInputPass}
                    value={confirmPass}
                    onChange={handleConfirmPass}
                    placeholder="Repetir contraseña"
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleTypeViewPass}
                                onMouseDown={handleTypeViewPass}
                            >
                                {typeInputPass === "password" ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </Form>
            {!loading ? (
                <Button disabled={pass !== "" && confirmPass !== "" ? false : true} onClick={handleSetPassword} variant="contained" color="primary">
                    Registrarme
                </Button>
            ) : (
                <ButtonLoading disabled={true} variant="contained" color="primary">
                    <PageLoading color="#777777" w="100%" h="100%" scale=".5" />
                    <div>Cargando</div>
                </ButtonLoading>
            )}


            {/* Snack */}
            <SnackBarGeneric
                show={snackBar.show}
                handleClose={closeSnackBar}
                message={snackBar.message}
                variant={snackBar.variant}
            />
        </RegisterLoginWrapper>
    }

    return <RegisterLoginWrapper>
        <Form>
            {typeDocument && <Select onChange={handleType} value={type} placeholder="Tipos de documento">
                {typeDocument.map(state => <MenuItem value={state.id}>{state.name}</MenuItem>)}
            </Select>}
            <TextField placeholder={'123456789'}  type="number" value={user} helperText={userError ? "" : "Cantidad de digitos inválido"} error={!userError} onChange={handleUser} label="Documento" />
            <TextField placeholder={'email@ejemplo.com'}  type="email" values={email} onChange={handleEmail} label="Email declarado en Damsu" />
            <TextField InputLabelProps={{ shrink: true }} type="date" values={date} onChange={handleDate} label="Fecha de nacimiento" />
        </Form>
        {!loading ? (
            <Button disabled={!!user && !!email && !!date ? false : true} onClick={handleSubmit} variant="contained" color="primary">
                Comprobar usuario
            </Button>
        ) : (
            <ButtonLoading disabled={true} variant="contained" color="primary">
                <PageLoading color="#777777" w="100%" h="100%" scale=".5" />
                <div>Cargando</div>
            </ButtonLoading>
        )}


        {/* Snack */}
        <SnackBarGeneric
            show={snackBar.show}
            handleClose={closeSnackBar}
            message={snackBar.message}
            variant={snackBar.variant}
        />
    </RegisterLoginWrapper>
}

export default RegisterLogin

const RegisterLoginWrapper = styled.div`
    width: 100%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const Form = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
`

const InputFake = styled(Input)`
    margin: 2rem 0;
`

const ButtonLoading = styled(Button)`
    display: flex;
    width: 150px;
    justify-content: space-between;
`