import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import CardGeneric from "../../components/common/CardGeneric";
import FormPharmacyHistory from "../../components/templates/pharmacy/FormPharmacyHistory";
import TableGeneric from "../../components/common/TableGeneric";
import DialogGeneric from "../../components/common/DialogGeneric";
import { ButtonBlue } from "../../components/common/ButtonsGenerics";
import MoreVertSharpIcon from "@material-ui/icons/MoreVertSharp";
import InfoHistory from "../../components/templates/pharmacy/InfoHistory";
import DownloadHistory from "../../components/ui/organisms/pharmacy/DownloadHistory";
import SearchGeneric from "../../components/common/SearchGeneric";


const ConsumtionHistory = () => {

    const [show, setShow] = useState(false)
    const [result, setResult] = useState(null);
    const [closeDialog, setCloseDialog] = useState(false)
    const [historyFiltered, setHistoryFiltered] = useState(null);
    const [history, setHistory] = useState(null);

    useEffect(() => {
        !!result && renderTable()
    }, [result]);
    

    const handleForceClose = () => {
        setCloseDialog(!closeDialog)
        setShow(false)
    }

    const handleResult = result => {
        setResult(result)
    }

    const handleFilter = filtered => {
        setHistoryFiltered(filtered)
    }

    const renderTable = () => {
        if (result) {
            let resultFunc = []
            result.map((row) => {
                return resultFunc.push(
                    {
                        ...row,
                        type: "Venta",
                        acciones: <Actions>
                            <DialogGeneric close={closeDialog} handleForceClose={handleForceClose} button={<ButtonBlue><MoreVertSharpIcon /></ButtonBlue>} component={<InfoHistory handleClose={handleForceClose} id={row.id}/>}/>
                        </Actions>,
                    }
                )

            })
            setHistory(resultFunc)
            return resultFunc
        }
        else {
            setHistory([])
            return []
        }
    }

    const columns = [
        { id: 'fecha_emision', label: 'Fecha de emisión' },
        { id: 'id', label: 'N° Orden' },
        { id: 'importe_total', label: 'Importe Total' },
        { id: 'type', label: 'Tipo de movimiento'},
        { id: 'acciones', label: 'Acciones' },
    ]

    return <ConsumtionHistoryWrapper>
        <CardGeneric title={"Historial de Consumos"} icon={<SearchRoundedIcon/>}>
            <FormPharmacyHistory handleResult={handleResult}/>
        </CardGeneric>
        {history && (
            <CardGeneric title={"Resultados"} icon={<SearchRoundedIcon/>}>
                <Header>
                    <SearchGeneric title="Buscar en farmacia" handleValues={handleFilter} filterNames={['id', 'importe_total', 'type']} values={history} />
                    <DownloadHistory print={historyFiltered || history}/>
                </Header>
                <TableGeneric columns={columns} rows={historyFiltered || history} pageSize={10}/>
            </CardGeneric>
        )}
    </ConsumtionHistoryWrapper>
}

export default ConsumtionHistory;

const ConsumtionHistoryWrapper = styled.div`
    width: 100%;
`

const Actions = styled.div`
    display: flex;
    justify-content: flex-start;
`

const Header = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`