import { Button } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';
import ArrowDownwardOutlined from '@material-ui/icons/ArrowDownwardOutlined';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';

const DownloadHistory = (props) => {
    const { print } = props;

    const d = new Date();
    const date = d.toISOString().slice(0, 10);

    const { user } = useSelector(state => state.auth)

    const headRows = () => {
        return [
            {
                fecha_emision: 'Fecha emisión',
                id: 'Id',
                importe_total: 'Importe total',
                type: 'Tipo',
            }
        ];
    };

    function bodyRows(rowCount, data) {
        rowCount = rowCount || 1;
        var body = [];
        for (var j = 1; j <= rowCount; j++) {
            body.push({
                fecha_emision: data[j - 1].fecha_emision,
                id: data[j - 1].id,
                importe_total: data[j - 1].importe_total,
                type: data[j - 1].type
            });
        }
        return body;
    }

    const generatePDF = async () => {
        console.log(print);
        try {
            const doc = new jsPDF('p', 'mm', 'a4');
            // IMG
            // let img = new Image();
            // img.src = Logo;
            // doc.addImage(img, 'png', 20, 10, 50, 10);
            // FONT
            doc.setTextColor('#757575');
            doc.setFontSize(12);
            doc.setFont('helvetica', 'bold');
            // TITLE
            doc.text('Historial de Consumos', 90, 17);
            //DATE
            doc.text(date, 170, 17);
            //USER
            let finalY = doc.lastAutoTable.finalY || 10;
            doc.text(`${user.nombre} ${user.apellido}`, 14, finalY + 20);
            //TABLE
            autoTable(doc, {
                theme: 'grid',
                startY: finalY + 25,
                margin: { top: 20, bottom: 20 },
                horizontalPageBreak: false,
                pageBreak: 'auto',
                tableWidth: '80%',
                head: headRows(),
                headStyles: {
                    fillColor: '#528bab',
                    fontStyle: 'bold',
                    textColor: '#FFFFFF',
                    cellPadding: 2,
                    halign: 'center',
                    valign: 'middle'
                },
                columnStyles: {
                    0: { halign: 'left' },
                    1: { halign: 'center' },
                    2: { halign: 'center' },
                    3: { halign: 'center' }
                },
                body: await bodyRows(print.length, print),
                styles: {
                    lineWidth: 0.1,
                    cellPadding: 1,
                    fontSize: 10,
                    overflow: 'linebreak',
                    cellWidth: 'auto'
                }
            });

            finalY = doc.lastAutoTable.finalY;
            doc.text(
                `Generado por Damsu on-line con fecha ${date}`,
                14,
                finalY + 10
            );

            doc.save(`historial_farmacia.pdf`);
        } catch (error) {
            console.log(error);
        }
    };

    const saveAsExcel = async () => {
        try {
            // Create a workbook with a worksheet
            const wb = new ExcelJS.Workbook();
            wb.views = [
                {
                    x: 0,
                    y: 0,
                    width: 10000,
                    height: 20000,
                    firstSheet: 0,
                    activeTab: 1,
                    visibility: 'visible',
                    showGridLines: true
                }
            ];
            const ws = wb.addWorksheet('UsersSheet', {
                pageSetup: {
                    paperSize: 9,
                    orientation: 'portrait',
                    margins: {
                        left: 0.7,
                        right: 0.7,
                        top: 0.75,
                        bottom: 0.75,
                        header: 0.3,
                        footer: 0.3
                    }
                }
            });
            //COLUMNS
            ws.columns = [
                { header: 'Fecha de emisión', key: 'fecha_emision', width: 30 },
                { header: 'Id', key: 'id', width:  10},
                { header: 'Importe total', key: 'importe_total', width: 15 },
                { header: 'Tipo', key: 'type', width: 15 },
            ];
            // ws.getCell('A2').value = print.name;
            print.forEach((data) => {
                ws.addRow({
                    name: print,
                    fecha_emision: data.fecha_emision,
                    id: data.id,
                    importe_total: data.importe_total,
                    type: data.type
                });
            });

            let bodyFill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'ffffff' }
            };
            let bodyBorder = {
                left: { style: 'thin' },
                right: { style: 'thin' }
            };
            let alignmentTex = {
                vertical: 'middle',
                horizontal: 'left',
                indent: 1
            };
            let alignmentNumber = { vertical: 'middle', horizontal: 'center' };

            ws.eachRow({ includeEmpty: false }, function (row) {
                row.eachCell({ includeEmpty: false }, function () {
                    //FILL BODY
                    row.getCell('A').fill = bodyFill;
                    row.getCell('B').fill = bodyFill;
                    row.getCell('C').fill = bodyFill;
                    //PERCENTAGE
                    row.getCell('E').numFmt = '0.00%';
                    //BORDERS
                    row.getCell('A').border = bodyBorder;
                    row.getCell('B').border = bodyBorder;
                    row.getCell('C').border = bodyBorder;
                });
            });
            //styles
            ['A1', 'B1', 'C1', 'D1'].map(
                (key) =>
                    (ws.getCell(key).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '528bab' }
                    })
            );
            ['A1', 'B1', 'C1', 'D1'].map(
                (key) =>
                    (ws.getCell(key).font = {
                        name: 'helvetica',
                        size: 12,
                        bold: true,
                        color: { argb: 'FFFFFF' }
                    })
            );
            ['A1', 'B1', 'C1', 'D1'].map(
                (key) =>
                    (ws.getCell(key).border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    })
            );
            ws.getRow(1).height = 30;
            ws.getRow(1).alignment = { vertical: 'middle' };
            ws.getColumn(1).alignment = alignmentTex;
            ws.getColumn(2).alignment = alignmentTex;
            ws.getColumn(3).alignment = alignmentNumber;
            ws.getColumn(4).alignment = alignmentNumber;
            ws.getColumn(5).alignment = alignmentNumber;

            // Generate & Save Excel File
            const buf = await wb.xlsx.writeBuffer();
            saveAs(new Blob([buf]), 'prestadores_externos.xlsx');
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <DownloadHistoryWrapper>
            <PdfButton
                size="small"
                endIcon={<PictureAsPdfRoundedIcon />}
                variant="contained"
                color="primary"
                onClick={generatePDF}
            >
                Exportar PDF
            </PdfButton>
            <ExcelButton
                size="small"
                variant="contained"
                color="primary"
                endIcon={<ArrowDownwardOutlined />}
                onClick={saveAsExcel}
            >
                Exportar EXCEL
            </ExcelButton>
        </DownloadHistoryWrapper>
    )
}

export default DownloadHistory

const DownloadHistoryWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
`
const PdfButton = styled(Button)`
    background-color: #b31d15;
    color: #f4f4f4; ;
`;
const ExcelButton = styled(Button)`
    background-color: #1d6f42;
    color: #f4f4f4;
`;