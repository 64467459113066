import React from 'react'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css'
import styled from 'styled-components';

const DefaultPhone = (props) => {
    return (
        <Input variant="outlined" country={'ar'} onlyCountries={['ar']} enableAreaCodes={['ar']} {...props}/>
    )
}

export default DefaultPhone

const Input = styled(PhoneInput)`
    width: 100%;
    min-width: 100%;
    .form-control{
        width: 485px;
        @media (max-width: 768px) {
            width: auto;
        }
    }
`