import React, { useEffect, useState } from "react";
import CardGeneric from "../../components/common/CardGeneric";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import styled from "styled-components";
import TableGeneric from "../../components/common/TableGeneric";
import { useSearch } from "../../hooks/useSearch";
import FormCurrentAccount from "../../components/templates/current-account/FormCurrentAccount";
import PageLoading from "../../components/common/PageLoading";
import { Text } from "../../components/common/Text";
import SearchGeneric from "../../components/common/SearchGeneric";
import { formatCurrency } from "../../helpers/currency.format.helper";

const CurrentAccount = () => {

    const [rowsRequest, setRows] = useState(null)

    // HOOKS
    const [filtered, setFiltered] = useState(null);

    const handleHistoryCurrentAccout = result => {
        renderTable(result)
    }

    // TABLE
    const columns = [
        { id: 'dateI', label: 'Fecha' },
        { id: 'tipo', label: 'T. Movimiento' },
        { id: 'nro', label: 'N° Movimiento' },
        { id: 'descripcion', label: 'Descripción' },
        { id: 'tipo_2', label: 'Tipo' },
        { id: 'importe', label: 'Importe' },
    ]

    const renderTable = (rowsRequest) => {
        if (rowsRequest) {
            let result = []
            rowsRequest.map((row) => {
                return result.push(
                    {
                        ...row,
                        importe: row.importe >= 0 ? <Text color="#944030" fontWeight="bold">$ {formatCurrency(row?.importe.toFixed(2) * 1)}</Text> : <Text color="#71a84e" fontWeight="bold">${formatCurrency(row?.importe.toFixed(2) * 1)}</Text>,
                        importeText: row.importe,
                        dateI: new Date(row.create_date).toLocaleDateString(),
                        tipo: row.importe >= 0 ? <Text color="#944030" fontWeight="700">DÉBITO</Text> : <Text color="#71a84e" fontWeight="700">CRÉDITO</Text>,
                        tipoText: row.importe >= 0 ? 'debito' : 'credito',
                        nro: row.id,
                        tipo_2: row.tipo || '-',
                    }
                )

            })
            setRows(result)
            return result
        }
        else {
            setRows([])
            return []
        }
    }

    const handleFilter = filtered => setFiltered(filtered)

    return <CurrentWrapper>
        <CardGeneric title={"Deuda histórica"} icon={<AccountBalanceWalletIcon />}>
            <FormCurrentAccount handleHistoryCurrentAccout={handleHistoryCurrentAccout} />
            {rowsRequest && (
                <>
                    <SearchGeneric margin={"15px 0"} title="Buscar en cuenta corriente" handleValues={handleFilter} filterNames={['cod', 'importeText', 'nro', 'tipoText']} values={rowsRequest} />
                    <TableGeneric columns={columns} rows={filtered || rowsRequest} pageSize={10} />
                </>
            )}
        </CardGeneric>
    </CurrentWrapper>
}

export default CurrentAccount;

const CurrentWrapper = styled.div`
`