import { ButtonBase } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { createShift } from '../../../../redux/api/shifts';
import { ButtonGreen } from '../../../common/ButtonsGenerics';
import PageLoading from '../../../common/PageLoading';
import { Text, Span } from '../../../common/Text';
import InfoUser from '../../../common/user/InfoUser';

export default function ConfirmShift(props) {
    const { form, currentDay, hour, afilliate, values, handleResult, handleClose, typeTurn } = props

    const [loading, setLoading] = useState(false)
    const [turnType, setTurnType] = useState(null)
    const [turnLabel, setTurnLabel] = useState('')
    
    useEffect(() => {
        getTypeTurn(typeTurn)
    }, [])
    
    

    const handleSubmit = async () => {
        setLoading(true)
        const data = {
            turno_id: form.hour,
            tipo_turno_id: turnType || 2,
            origen_id: 2,
            afiliado_id: form.affiliate
        }
        const request = await createShift(data)
        if(request.error) {
            setLoading(false)
            handleResult(false)
        }
        else{
            setLoading(false)
            handleResult(true)
        }
        handleClose()
    }

    const getTypeTurn = (type) => {
        if (!type) {
            if (hour.presencial && !hour.telefonico) {
                setTurnType(2)
                setTurnLabel('Presencial')
            }
            else if (!hour.presencial && hour.telefonico) {
                setTurnType(1)
                setTurnLabel('Telefónico')
            }
            else{
                setTurnLabel('Error')
            }
        }
        else if(type === 'presencial'){
            setTurnType(2)
            setTurnLabel('Presencial')
        }
        else if(type === 'telefonico'){
            setTurnType(1)
            setTurnLabel('Telefónico')
        }
        else{
            setTurnLabel('Error')
        }
    }

    return (
        <ConfirmShiftWrapper>
            <Header>
            <LogoWrapper>
                <Text fontWeight="600" color="white" fontSize="3rem">?</Text>
            </LogoWrapper>
        </Header>
        <Body>
            <Text fontWeight="600" color="#555555" fontSize="2rem">¿Desea confirmar turno?</Text>
            <InfoUser affiliate={afilliate} />
            <Text fontWeight="600" fontSize="1rem"><Span fontWeight="700" fontSize="1rem">Horario: </Span> {hour.hora}hs</Text>
            <Text fontWeight="600" fontSize="1rem"><Span fontWeight="700" fontSize="1rem">Dia: </Span>{currentDay.fecha}</Text>
            <Text fontWeight="600" fontSize="1rem"><Span fontWeight="700" fontSize="1rem">Especialidad: </Span>{values.speciality}</Text>
            <Text fontWeight="600" fontSize="1rem"><Span fontWeight="700" fontSize="1rem">Prestación: </Span>{hour.prestacion}</Text>
            <Text fontWeight="600" fontSize="1rem"><Span fontWeight="700" fontSize="1rem">Profesional: </Span>{values.profesional}</Text>
            <Text fontWeight="600" fontSize="1rem"><Span fontWeight="700" fontSize="1rem">Tipo de turno: </Span>{turnLabel}</Text>
        </Body>
        {!loading ? (
            <Footer>
                <ButtonGreen onClick={handleSubmit}>Confirmar turno</ButtonGreen>
                <ButtonBase  onClick={handleClose}>Cancelar operación</ButtonBase>
            </Footer>
        ):(
            <PageLoading w="100%" h="100px"/>
        )}
        </ConfirmShiftWrapper>
    )
}

const ConfirmShiftWrapper = styled.div`
    min-width: 450px;
    min-height: 250px;
    @media (max-width: 800px) {
        min-width: auto;
    }
`

const Header = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Body = styled.div`
    width: 100%;
    margin: 3rem 0;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
`

const LogoWrapper = styled.div`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #7dbd6a;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Footer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
`